<template>
  <div class="form-login">
    <v-form
      @keyup.native.enter="executeLogin"
    >
      <ValidationProvider
        v-slot="{errors}"
        name="email"
        rules="required|email"
      >
        <v-text-field
          v-model="email"
          :label="$t('components.labels.email')"
          type="email"
          outlined
          color="accent"
          :error-messages="errors"
        />
      </ValidationProvider>

      <!--Campo de senha com a opção   -->
      <ValidationProvider
        v-slot="{errors}"
        name="senha"
        rules="required|min:6"
        class="login-password"
      >
        <v-text-field
          v-model="password"
          :label="$t('components.labels.password')"
          :type="showPasswordCondition"
          outlined
          color="accent"
          :error-messages="errors"
          :append-icon="showPasswordIcon"
          @click:append="changePassword"
        />
      </ValidationProvider>

      <div class="text-xs-center">
        <router-link
          class="text-right float-right"
          to="/recuperar-senha"
        >
          {{ $t('components.login_form.forgot_password') }}
        </router-link>

        <v-btn
          class="m-btn"
          block
          large
          color="accent"
          @click="executeLogin"
        >
          {{ $t('components.login_form.login_btn') }}
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
export default {
  name: 'LoginForm',
  data() {
    return {
      email: '',
      password: '',
      showPassword: false,
    };
  },
  computed: {
    showPasswordCondition() {
      return this.showPassword ? 'text' : 'password';
    },
    showPasswordIcon() {
      if (this.showPassword) {
        return this.$t('components.login_form.password.hide');
      }

      return this.$t('components.login_form.password.show');
    },
  },
  methods: {
    executeLogin() {
      const managerData = { email: this.email, password: this.password };
      this.$emit('submited', managerData);
    },

    changePassword() {
      this.showPassword = !this.showPassword;
    },
  },
};
</script>

<style lang="scss" scoped>
.login-btn {
  margin: 120px 0 30px;
}
a {
  font-size: 16px;
  width: 100%;
  color: black;
}
.m-btn {
  display: block;
  position: relative;
  top: 60px;
  background-color: $color_primary;
  height: 56px !important; // Overwrite
}
</style>
